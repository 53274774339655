<template>
  <PengawasanDekomForm mode="Tambah" module="Laporan Tugas Pengawasan Dekom"> </PengawasanDekomForm>
</template>

<script>
import PengawasanDekomForm from './form';

const PengawasanDekomAdd = {
  name: 'PengawasanDekomAdd',
  components: { PengawasanDekomForm },
};

export default PengawasanDekomAdd;
</script>
